import { useEffect } from 'react';

import { navigate } from 'gatsby';

export default (): null | void => {
  useEffect(() => {
    void navigate('/');
  }, []);

  return null;
};
